import React, { useRef, useState } from 'react'
import {
    Box,
    Icon,
    useToken,
    IconButton,
    Center,
    usePrefersReducedMotion,
    useColorMode,
} from "@chakra-ui/react"
import { sample } from 'lodash'
import * as beats from './beats'
import type { SongProp } from './beats'
import { useResizeDetector } from 'react-resize-detector'
import { FaPlayCircle } from 'react-icons/fa'
import { MotionBox } from '../../components/atoms/MotionBox'


type BeatsComponent = React.FC<SongProp>

type JSXBeatsProps = {
    Song?: BeatsComponent
}

const randomBeat = sample(beats) as BeatsComponent

function JSXBeatsDemo({ Song = randomBeat }: JSXBeatsProps) {
    const prefersReducedMotion = usePrefersReducedMotion()
    const {colorMode} = useColorMode()

    const [playing, setPlaying] = useState(false)
    
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const { width = 512, height = 448, ref } = useResizeDetector()
    
    const [gradientStart, gradientMiddle,gradientEnd] = useToken('colors', ['blue.100', 'blue.500', 'purple.500'])
    
    function handleAudioProcess(analyser) {
        const ctx = canvasRef?.current?.getContext("2d")

        if (ctx && analyser) {
            const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.clientHeight)
            if (prefersReducedMotion) {
                gradient.addColorStop(1, colorMode === 'light' ? 'rgba(0,0,0,0.005)' : 'rgba(255,255,255,0.005)')
                gradient.addColorStop(0, colorMode === 'light' ? 'rgba(0,0,0,0.05)' : 'rgba(255,255,255,0.05)')
            } else {
                gradient.addColorStop(1, gradientStart)
                gradient.addColorStop(0.5, gradientMiddle)
                gradient.addColorStop(0, gradientEnd)
            }

            const array = new Uint8Array(analyser.frequencyBinCount)
            analyser.getByteFrequencyData(array)
            ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)
            ctx.fillStyle = gradient

            for (let i = 0; i < array.length; i++) {
                const value = array[i]
                ctx.fillRect(
                    i * (ctx.canvas.clientWidth / array.length),
                    ctx.canvas.clientHeight,
                    ctx.canvas.clientWidth / array.length - 2,
                    value * -0.95
                )
            }
        }
    }

    return (
        <Box
            ref={ref}
            w="full"
            h={80}
            maxWidth="calc(100vw - 2rem)"
            isolation="isolate"
            position="relative"
            borderRadius="md"
            overflow="hidden"
        >
            <Song playing={playing} onAudioProcess={handleAudioProcess} />
            <canvas ref={canvasRef} width={width} height={height} />
            <MotionBox
                as={Center}
                h="full"
                w="full"
                zIndex={5}
                userSelect="none"
                position="absolute"
                inset={0}
                bgGradient="linear(to-t, blue.300, blue.100, blue.50, whiteAlpha.900)"
                _dark={{
                    bgGradient: "linear(to-t, blue.900, blackAlpha.50)",
                }}
                animate={{
                    opacity: playing ? 0 : 1,
                }}
                onClick={() => setPlaying(!playing)}
            >
                <MotionBox
                    boxSize="25%"
                    animate={{
                        scale: playing ? 0 : 1,
                        y: playing && !prefersReducedMotion ? "200%" : 0,
                    }}
                    whileHover={{
                        scale: playing ? 0 : 1.1,
                    }}
                >
                    <IconButton
                        aria-label={playing ? "pause" : "play"}
                        variant="unstyled"
                        _focus={{
                            boxShadow: "none",
                        }}
                        _focusVisible={{
                            boxShadow: "outline",
                        }}
                        boxSize="full"
                        icon={
                            <Icon
                                as={FaPlayCircle}
                                boxSize="full"
                                color="blue.500"
                                _dark={{
                                    color: "blue.200",
                                }}
                            />
                        }
                    />
                </MotionBox>
            </MotionBox>
        </Box>
    )
}



export {JSXBeatsDemo}