import React, { useMemo } from "react"
import { Song, Sequencer, Synth, Monosynth, Reverb, Analyser } from "react-music"


export type SongProp = {
    playing: boolean;
    onAudioProcess: (analyser: any) => void;
}

function AllTheWayDown({ playing, onAudioProcess }: SongProp) {
    const steps = useMemo(() => generateAllTheNotes(), [])
    return (
        <Song tempo={126} playing={playing}>
            <Analyser onAudioProcess={onAudioProcess}>
                <Sequencer resolution={32} bars={4}>
                    <Synth type="sine" steps={steps} />
                </Sequencer>
            </Analyser>
        </Song>
    )
}

function DubDub({ playing, onAudioProcess }: SongProp) {
    return (
        <Song tempo={120} playing={playing}>
            <Analyser onAudioProcess={onAudioProcess}>
                <Sequencer resolution={16} bars={1}>
                    <Monosynth
                        glide={0.5}
                        type="triangle"
                        steps={[
                            [0, 4, "c3", "G#3", "B#4"],
                            [2, 8, "c4", "E5"],
                        ]}
                    />
                    <Synth
                        type="square"
                        steps={[
                            [0, 4, "A3", "C4", "E4"],
                            [4, 4, "E4", "G4", "B4"],
                            [8, 4, "B4", "C5", "E5"],
                            [12, 4, "E5", "G5", "B5"],
                        ]}
                    />
                    <Reverb>
                        <Synth
                            type="sawtooth"
                            steps={[
                                [5, 1, "a4"],
                                [2, 6, "f4"],
                                [7, 1, "a4"],
                                [2, 6, "f4"],
                                [7, 1, "a4"],
                                [2, 6, "f4"],
                            ]}
                        />
                    </Reverb>
                    <Synth
                        type="triangle"
                        steps={[
                            [6, 2, "A3", "D#3", "F#4"],
                            [4, 4, "B4", "D5"],
                        ]}
                    />
                </Sequencer>
            </Analyser>
        </Song>
    )
}

function LookingFromTheWindow({ playing, onAudioProcess }: SongProp) {
    return (
        <Song tempo={90} playing={playing}>
            <Analyser onAudioProcess={onAudioProcess}>
                <Sequencer resolution={16} bars={1}>
                    <Monosynth
                        glide={0.5}
                        type="triangle"
                        steps={[
                            [0, 4, "c3"],
                            [4, 4, "c4"],
                        ]}
                    />
                    <Synth
                        type="sine"
                        steps={[
                            [0, 4, "A3", "C4", "E4"],
                            [4, 4, "E4", "G4", "B4"],
                            [8, 4, "B4", "C5", "E5"],
                            [12, 4, "E5", "G5", "B5"],
                        ]}
                    />
                </Sequencer>
            </Analyser>
        </Song>
    )
}

function PyramidBeats({ playing, onAudioProcess }: SongProp) {
    return (
        <Song tempo={90} playing={playing}>
            <Analyser onAudioProcess={onAudioProcess}>
                <Sequencer resolution={30} bars={1}>
                    <Synth
                        type="triangle"
                        steps={[
                            [0, 2, "b1"],
                            [2, 4, "b2"],
                            [4, 6, "b3"],
                            [6, 8, "b4"],
                            [8, 10, "b5"],
                            [10, 12, "b6"],
                            [12, 4, "b7"],
                            [14, 2, "b8"],
                            [16, 4, "b7"],
                            [18, 12, "b6"],
                            [20, 22, "b5"],
                            [22, 24, "b4"],
                            [24, 26, "b3"],
                            [26, 28, "b2"],
                            [28, 30, "b1"],
                        ]}
                    />
                </Sequencer>
            </Analyser>
        </Song>
    )
}

function generateAllTheNotes({ gap = 2 } = {}) {
    const notes = ["A", "B", "C", "D", "E", "F", "G"]
    const scales = [1, 2, 3, 4, 5, 6, 7, 8]

    return scales.flatMap((scale, scaleIndex) =>
        notes.map((note, noteIndex) => {
            const startBar = scales.length * scaleIndex * gap + noteIndex * gap
            return [startBar, 2, `${note}${scale}`]
        })
    )
}



export {AllTheWayDown, DubDub, LookingFromTheWindow, PyramidBeats}